<template>
  <div class="section">
    <!-- 
    <b-button @click="testSocket(2345)">test sock</b-button>
      -->
    <div class="container home" v-if="bot && bot.handle">
      <br />
      <br />
      <br />
      <div class="columns is-5 is-variable">
        <div class="column is-3">
          <div class="sticky-content">
            <!-- LEFT COL  -->

            <b-dropdown
              @change="changeBotRoute"
              :scrollable="true"
              has-link
              :max-height="600"
              v-model="selectedBot"
              aria-role="list"
            >
              <template #trigger>
                <h1 class="title is-3">
                  {{ bot.name || bot.handle }}

                  <router-link :to="'/bots/' + bot.url + '/plan'" class="icon-link">
                    <span class="tag is-success is-light is-small is-mini" v-if="bot.visibilityMode == 'public'"
                      >Public bot
                    </span>
                    <span class="tag is-danger is-light is-small is-mini" v-else> Private bot </span>
                  </router-link>
                </h1>
                <!--  
              <b-button
                :label="selectedBot.text"
                type="is-primary"
                :icon-left="selectedBot.icon"
                icon-right="menu-down"
              />-->
              </template>

              <b-dropdown-item v-for="(b, index) in bots" :key="index" :value="b.handle" aria-role="listitem">
                <div class="media">
                  <figure class="image is-32x32 media-left">
                    <img :src="b.avatar" class="avatar" />
                  </figure>

                  <div class="media-content">
                    <h3>{{ b.handle }}</h3>
                    <small>{{ b.access }}</small>
                  </div>
                </div>
              </b-dropdown-item>
            </b-dropdown>

            <!--  
          <h1 class="title is-3">
            <router-link :to="bot.url" class="regularText">
              {{ bot.name || bot.handle }}
            </router-link>

            <router-link :to="bot.url + '/manage/plan'" class="icon-link">
              <span class="tag is-success is-light is-small is-mini" v-if="bot.visibilityMode == 'public'"
                >Public bot
              </span>
              <span class="tag is-danger is-light is-small is-mini" v-else> Private bot </span>
            </router-link>
          </h1>-->

            <h1 class="subtitle is-6">
              {{ bot.bio }}
            </h1>

            <span v-for="i in iconLinks" :key="i.t + 'fdsdgf7'" class="iconLinks">
              <b-tooltip multilinedNO position="is-bottom" type="is-dark" :label="i.t">
                <a :href="i.u" target="_blank" v-if="String(i.u).startsWith('http')">
                  <span class="icon icon-medium"> <i :class="' ' + i.i" aria-hidden="true"></i> </span>
                </a>
                <router-link :to="i.u" v-else>
                  <span class="icon icon-medium"> <i :class="' ' + i.i" aria-hidden="true"></i> </span>
                </router-link>
              </b-tooltip>
            </span>
            <br />
            <router-link :to="bot.url" class="regularText">
              <img
                :src="bot.avatar"
                class="avatar is-hidden-mobile"
                style="width: 60%; max-width: 20vw; border-radius: 600px"
              />
            </router-link>
            <!-- 
        <img alt="Vue logo" src="../assets/logo.png" />
https://cdn11.bigcommerce.com/s-k6q7hvb4ne/images/stencil/1280x1280/products/42374/23193/MB113__80899.1654309088.jpg
        https://images.emojiterra.com/google/noto-emoji/v2.034/512px/1f6e0.png

        -->
          </div>
        </div>
        <div class="column">
          <div class="tabs" style="margin-bottom: 60px">
            <ul>
              <router-link
                tag="li"
                v-for="route in nav"
                :key="route.path"
                :to="{ path: basepath + route.path }"
                active-class="is-active"
                exact
              >
                <a>
                  <span class="icon is-small">
                    <i :class="route.icon" aria-hidden="true"></i>
                  </span>
                  <span>{{ route.label }}</span>
                </a>
              </router-link>

              <!--  

           <h2 class="title is-4">Posting</h2>

              <router-link tag="li" :to="{ name: 'TabView3' }"
                ><a>Tab 3</a></router-link
              >
              <router-link tag="li" :to="{ name: 'TabView4' }"
                ><a>Tab 4</a></router-link
              > -->
            </ul>
          </div>

          <router-view :bot="bot" v-if="bot && !loading" @save="save" :posting="posting"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { h } from "vue";
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    // HelloWorld,
  },
  metaInfo() {
    return {
      title: this.title,
    };
  },

  data() {
    return {
      bot: { name: "..." },
      loadingbot: true,
      loadingOps: true,
      ops: [],
      posting: false,
      selectedBot: null,
      loading: false,

      //
      loadingBots: true,

      bots: [
        // Add more bots here...
      ],
      // selectedBot: { name: "Bot 1", handle: "bot1", avatar: "bot-icon-1", access: "Public" },
    };
  },

  mounted() {
    this.loadProfile();
  },
  //watch params.bot changes
  watch: {
    "$route.params.bot"(to, from) {
      // react to route changes...
      this.loadProfile();
    },
  },
  methods: {
    changeBotRoute: function (handle) {
      //  alert("changeBotRoute" + handle);
      // Store the current route and its params
      var r = this.$route;
      var currentPath = r.path;
      var old = r.params.bot;
      var newPath = currentPath.replace(old, handle);

      //   console.log("changeBotRoute", handle, r, newPath);
      /*
      let currentRoute = Object.assign({}, r);
      // Change the param in the copied route
      console.log("ass", currentRoute);
      currentRoute.params.bot = handle;
      // Push the new route
      console.log("pushing", currentRoute);*/
      this.$router.push(newPath);

      // this.$router.push("/" + handle + "/manage");
    },
    loadProfile(index) {
      var id = this.$route.params.bot;
      this.loadingBots = true;
      this.loading = true;
      //this.bot = {};

      var opt = {};
      // if is admin, add moderator option.
      /*
      if (this.$store.main.getters.isAdmin) {
        opt = { moderator: true };
      }*/

      var isAdmin = this.$store.main.getters.isAdmin;
      var method = isAdmin ? "botProfileAdmin" : "botProfileOwner";
      window.API[method](id, opt).then((b) => {
        this.bot = b;
        this.loading = false;
        // console.log("botJ!", bot, bot.id);
      });

      window.API.me.getUserBotsOwner().then((bots) => {
        this.bots = bots;
        this.loadingBots = false;
      });
    },
    save: function (cb) {
      this.posting = true;
      // `this` inside methods point to the Vue instance
      var d = this.bot;
      // alert("Hello " + d + "!");
      // `event` is the native DOM event
      console.log(d);

      // d = { H3334: 343254354354353, id: "dashApi" };

      let method;
      //ad is admin
      if (this.$route.name == "BotManageModerate" && this.$store.main.getters.isAdmin) {
        method = "moderateBot"; //PUT, for admins only
      } else {
        method = "editBot";
      }

      window.API[method](d.handle, d).then((bot) => {
        this.posting = false;
        //snackbar black buefy

        this.$buefy.snackbar.open({
          message: "Changes saved",
          type: "is-success",
          position: "is-bottom-right",
          actionText: "Close",
          indefinite: false,
        });

        if (cb) cb(true);

        //bot updated!
      });
    },
  },

  computed: {
    basepath() {
      return "/bots/" + this.$route.params.bot;
      return "/" + this.$route.params.bot + "/manage";
    },
    nav() {
      /*
      var nav = [
        { path: "/design", icon: "fal fa-drafting-compass", label: "Profile" },
        { path: "/packages", icon: "far fa-code", label: "Posts" },
        { path: "/packages", icon: "far fa-code", label: "Interactivity" },
        { path: "/deploy", icon: "far fa-rocket", label: "Engines" },
        { path: "/stats", icon: "far fa-chart-line", label: "Performance" },
        { path: "/settings", icon: "far fa-cog", label: "Settings" },
        {
          label: "Mod / Admin",
          icon: "fal fa-wrench",
          path: "/admin",
          isAdmin: true,
        },
      ];*/
      /*      Content
                - what gets posted, dometime automatically.


                Interactions
                - messaging, highlight on profile



                Behavior - BIG SECTION, could go to child...
                 - respond to message
                 - create image for me, for users
                Appearance - Could just be an item in general. edit profile link...
                Posts - kind of history + stats
                Insights - stats

                "fas fa-slider


                drafting-compass*/

      var nav1 = [
        { path: "", icon: "fal fa-sparkles", label: "Posts" },
        { path: "/behavior", icon: "far fa-sliders-h", label: "Behavior" },
        // { path: "/posts", icon: "far fa-poo", label: "Posts" },
        { path: "/insights", icon: "far fa-chart-line", label: "Insights" },
        { path: "/integrations", icon: "far fa-bolt", label: "Integrations" },

        { path: "/settings", icon: "fal fa-cog", label: "Settings" },
        {
          label: "Modmin",
          icon: "fal fa-wrench",
          path: "/admin",
          isAdmin: true,
        },
      ];
      var nav2 = [
        /*
        { path: "", icon: "fal fa-sparkles", label: "Posts" },
        { path: "/behavior", icon: "far fa-sliders-h", label: "Behavior" },
        // { path: "/posts", icon: "far fa-poo", label: "Posts" },
        { path: "/insights", icon: "far fa-chart-line", label: "Insights" },
        { path: "/integrations", icon: "far fa-bolt", label: "Integrations" },

        { path: "/settings", icon: "fal fa-cog", label: "Settings" },
        {
          label: "Modmin",
          icon: "fal fa-wrench",
          path: "/admin",
          isAdmin: true,
        },


        */
        { path: "/", icon: "far fa-cog", label: "Settings" }, //Functions  Behavior  Settings
        // { path: "/settings", icon: "far fa-user-circle", label: "Profile" },
        // play seedling   stream  cogs
        //   { path: "/programs", icon: "far fa-stream", label: "Programs" },
        // rocket code stream
        { path: "/posts", icon: "far fa-stream", label: "Posts" },
        // { path: "/posts", icon: "far fa-poo", label: "Posts" },
        { path: "/insights", icon: "far fa-chart-line", label: "Insights" },
        //{  { path: "/integrations", icon: "far fa-bolt", label: "Integrations" },

        // { path: "/insights", icon: "far fa-chart-line", label: "Insights" },

        // { path: "/settings", icon: "far fa-cog", label: "Settings" },
        /**/
        {
          label: "Modmin",
          icon: "fal fa-shield",
          path: "/admin",
          isAdmin: true,
        },

        //  { path: "/settings", icon: "far fa-cog", label: "Advanced" },
      ];

      nav2 = nav2.filter((m) => {
        if (m.isAdmin && !this.$store.main.getters.isAdmin) return false;
        return true;
      });
      return nav2;
    },
    titleBarTitle() {
      // if (this.isBotSettingsHome) return "Chats";
      if (this.isNewMessage) return this.$route.params.bot + " ✨ ";
      if (this.bot) return this.bot.handle;
      var rb = this.$route.params.bot;
      if (rb) return rb;
      return ""; //prevent flickering, but let user go back while slow load.
    },
    title() {
      return this.titleBarTitle + " / configure";
    },
    iconLinks() {
      var p = this.bot;
      var autoAirtable = p.airtableBase ? "https://airtable.com/" + p.airtableBase : null;
      var npm = p.npmName ? "https://www.npmjs.com/package/" + p.npmName : null;

      var aa = [
        { t: "Profile", i: "far fa-user", u: p.url },

        { t: "Chat", i: "far fa-comment", u: "/messages" + p.url },
        //   { t: "New post", i: "far fa-plus-circle", u: p.url + "/new" },

        { t: "Website", i: "fal fa-globe", u: p.website },
        { t: "Twitter", i: "fab fa-twitter", u: p.twitter ? "https://twitter.com/" + p.twitter : null },
        //  { t: "Edit in Airtable", i: "fal  fa-table", u: p.airtableUrl || autoAirtable },
        { t: "npm package (front-end)", i: "fab fa-npm", u: npm },

        { t: "Backend git repo", i: "fal fa-code-branch", u: p.backendRepoUrl },
        { t: "Backend CI Dashboard", i: "fal fa-rocket", u: p.backendDashUrl },
        { t: "Database", i: "fal fa-database", u: p.backendDbManagerUrl },
        { t: "Cloudflare", i: "fal fa-cloud", u: p.backendCloudflareUrl },
      ];
      aa = aa.filter((i) => i.u);
      return aa;
      /*
      var obj = {
        globe: p.website,
        table: p.airtableUrl || autoAirtable,
        npm,
        //  table:
      };
      var ar = Object.entries(obj); // obj.map((i,u) =>{
      ar = ar.filter((i) => i[1]); //remove empties
      ar = ar.map((k) => {
        return { i: k[0], u: k[1] };
      }); //remove empties
      return ar;*/
    },
  },
};
</script>
<style scoped>
.iconLinks a {
  color: #aaa;
}
.iconLinks a:hover {
  color: black;
}
.iconLinks .icon {
  margin-right: 20px;
  font-size: 28px;
  margin-bottom: 20px;
}

.sticky-content {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 40px; /* or whatever offset you want from the top */
  z-index: 100; /* optional, to make sure it's on top */
}
</style>
